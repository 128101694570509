<template lang="pug">
	.dashboard
		h2.mb-4 {{ $t('menu.dashboard') }}
		.page
			DashboardIncome
			DashboardGraphics(@changeFilter="changeFilter" :isLoading="isLoading")
			.whiteCard.foreground-color.mb-4.vuetable
				.d-flex.align-items-center.mb-3.justify-content-between
					.left
						my-input-search.mr-2.finance__search(
							v-model="filters.query"
							:placeholder="$t('dashboards.search')"
						)
					.d-flex
						.d-flex.justify-content-between.align-items-center(v-if="!isMobile")
							.b1.mr-2 {{ $t('dashboards.hideInactive') }}
							switches(v-model="filters.hideInactive" :disabled='isPending')
						table-per-page.ml-3(v-if="total1 && !isMobile" @onChange="selectPageSize" :from="from1" :total="total1")

				.d-flex.align-items-center.gap-2.mb-3(v-if="isMobile")
					.b1.mr-2 {{ $t('dashboards.hideInactive') }}
					switches(v-model="filters.hideInactive" :disabled='isPending')

				MerchantPartnersDashboardTable(
					:perPage='filters.itemsPerPage'
					:filters='filters'
					:hideSearch="true"
					:hidePerPage="true"
					@setIsLoading="isLoading=$event"
				)

		merchant-partner-has-no-projects(v-if="showModalHasNoProject")
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardGraphics from '@/components/Cards/merchantPartner/DashboardGraphics';
import DashboardIncome from '@/components/Cards/merchantPartner/DashboardIncome';
import MerchantPartnersDashboardTable from '@/components/Tables/MerchantPartnersDashboardTable.vue';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';
import moment from 'moment-timezone';
import { STORAGE_PARTNER_PROJECT_ID } from '@/api/storage';
import { DOMAIN } from '@/api';
import MyInputSearch from '@/components/UI/MyInputSearch.vue';
import TablePerPage from '@/components/Tables/TablePerPage.vue';
import Switches from 'vue-switches';
import MerchantPartnerHasNoProjects from '@/components/Modals/MerchantPartnerHasNoProjects.vue';

export default {
    name: 'MerchantPartnerDashboard',
    components: {
        MerchantPartnerHasNoProjects,
        DashboardGraphics,
        DashboardIncome,
        ProjectAlerts,
        MyInputSearch,
        MerchantPartnersDashboardTable,
        TablePerPage,
        Switches,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        isLoading: false,
        alerts: [],
        filters: {
            itemsPerPage: 25,
            query: null,
            addProjectPending: false,
            hideInactive: false,
            tz: moment.tz.guess(),
        },
    }),
    computed: {
        ...mapGetters({
            stats: 'merchantPartner/statistics',
            isPending: 'project/isPending',
            me: 'auth/me',
            mePending: 'auth/isPending',
        }),

        getTitle() {
            return this.$t('menu.dashboard');
        },

        from1() {
            return this.stats?.projectList.length;
        },

        total1() {
            return this.stats?.projectListTotalItems;
        },
        hasPartnerAddLink() {
            return !!this.$cookies.get(STORAGE_PARTNER_PROJECT_ID);
        },
        showModalHasNoProject() {
            const storage = !!localStorage.hasNoProject;
            return (
                this.me &&
                !this.me.hasProjects &&
                !this.hasPartnerAddLink &&
                !this.addProjectPending &&
                !this.mePending &&
                !storage
            );
        },
    },

    methods: {
        ...mapActions({
            getStatistics: 'merchantPartner/statistics',
            addLinkToPartner: 'merchantPartner/add',
            getMe: 'auth/me',
        }),

        async initProject() {
            this.getStatistics({
                tz: moment.tz.guess(),
            });
        },

        close() {
            this.$bvModal.hide('creation-success');
        },
        selectPageSize(perPage) {
            this.filters.itemsPerPage = perPage;
        },

        changeFilter(value) {
            this.filters = { ...this.filters, ...value };
        },

        initAddLinkToPartner() {
            const projectId = this.$cookies.get(STORAGE_PARTNER_PROJECT_ID);
            if (projectId) {
                this.addProjectPending = true;
                this.addLinkToPartner({ projectId })
                    .then(res => {
                        this.addProjectPending = false;
                        this.$router.push({ name: 'project_id', params: { id: res } });
                        if (!this.me.hasProjects) {
                            setTimeout(() => {
                                this.$bvModal.show(`mp-can-invite-subscribers`);
                            }, 2000);
                        }
                        this.$cookies.remove(STORAGE_PARTNER_PROJECT_ID, null, DOMAIN);
                    })
                    .catch(e => {
                        let err = e.split(': ');
                        if (err[0] === 'Link exist' && parseInt(err[1])) {
                            this.$router.push({ name: 'project_id', params: { id: err[1] } });
                        }
                        this.$cookies.remove(STORAGE_PARTNER_PROJECT_ID, null, DOMAIN);
                    });
            }
        },
    },
    created() {
        //  this.initProject();
        this.getMe();
        this.initAddLinkToPartner();
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.dashboard .vuetable tbody tr td:nth-child(1) {
    @include respond-below(sm) {
        min-width: 250px;
    }
}
.dashboard .vuetable tbody tr td:nth-child(2) {
    display: table-cell;
}
</style>
