<template lang="pug">
	extends ./table.pug

	block tableTH

		template(v-slot:cell(project)='data')
			router-link(:to="{name: 'project_id', params: {id: data.item.id}}" v-html="data.item.title")
			div(v-if="data.item.status!=='ACTIVE'" )
				b-badge.mt-1.bageInfo.d-block(pill variant='danger'  v-html="$t('merchantPartners.alerts.unavailable2')")
			div(v-if="changeRateBadge(data.item)")
				b-badge.mt-1.bageInfo.d-block(variant='orange' v-html="$t('merchantPartners.alerts.changeRate',changeRateBadge(data.item))")

		template(v-slot:cell(conversion)='data')
			.convers(v-html="conversionFormat(data.item.conversion)")

		template(v-slot:cell(income)='data')
			.other(v-html="incomeFormat(data.item.incomeOther)")
			.world(v-html="incomeFormat(data.item.incomeWorld2ru)")

</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import UserShortData from '@/components/Common/UserShortData';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { SERVICE_STRING } from '@/utils/string';
import { CURRENCY, Money } from '@/models/money';
import InfoTooltip from '@/components/Common/InfoTooltip';
import IconClose from '@/components/Common/IconClose';
import moment from 'moment-timezone';
import filters from '@/filters';

export default {
    name: 'MerchantPartnersDashboardTable',
    mixins: [TableMixin],
    components: {
        TableSearch,
        TableShowMore,
        UserShortData,
        ModalTooltip,
        InfoTooltip,
        IconClose,
    },
    props: {
        role: {
            type: String,
            default: 'item',
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            noLocalSorting: true,
            sortBy: 'createdAt',
            query: null,
            searchKeys: ['createdAt'],
            sorting: ctx => {
                let data = { ...this.filters };
                data[`order[${ctx.sortBy}]`] = 'desc';

                //	data['tz']= moment.tz.guess()

                this.isbusy = true;
                this.getStatistics(data).finally(() => {
                    this.isbusy = false;
                });
            },
            fields: [
                {
                    key: 'project',
                    label: this.$t('h1.project'),
                },
                {
                    key: 'subscriptionsViews',
                    label: this.$t('project.views'),
                    sortable: true,
                },
                {
                    key: 'subscriptionsActive',
                    label: this.$t('finance.subscriptions'),
                    sortable: true,
                },
                {
                    key: 'conversion',
                    label: this.$t('merchantPartners.catalog.conversion'),
                    sortable: true,
                },
                {
                    key: 'subscriptionsRenew',
                    label: this.$t('project.recurrent'),
                    sortable: true,
                },
                {
                    key: 'subscriptionsRefound',
                    label: this.$t('project.refund'),
                    sortable: true,
                },
                {
                    key: 'subscriptionsCanceled',
                    label: this.$t('project.cancelled'),
                    sortable: true,
                },
                {
                    key: 'income',
                    label: this.$t('partners.income'),
                    sortable: true,
                },
            ],
        };
    },

    mounted() {
        bus.$on('tableFollowersRefresh', query => {
            this.searching(query);
        });
        this.emptyText = this.$t('project.notActiveYet');

        this.searching();
    },
    created() {
        this.searching = _.debounce(query => {
            this.query = query;
            this.sorting(this);
        }, 400);
    },
    destroyed() {
        bus.$off('tableFollowersRefresh');
        bus.$off('tableFollowersExport');
        this.$store.commit('finance/setSubscriptionsTotal', 0);
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPendingSubcriptions',
            totalItems: 'finance/subscriptionsTotal',
            stats: 'merchantPartner/statistics',
        }),
        serviceString() {
            return SERVICE_STRING;
        },
        currency() {
            return CURRENCY;
        },
        items() {
            return this.stats?.projectList;
        },
    },
    methods: {
        ...mapActions({
            get: 'project/getPartnersList',
            getStatistics: 'merchantPartner/statistics',
        }),

        changeRateBadge(item) {
            if (!item.rateNew) return;
            const rateChangedAt = filters.dateDDMMMYYDots(item.rateChangedAt);
            return { rateChangedAt: rateChangedAt, rateNew: item.rateNew };
        },
        incomeFormat(moneyCollection) {
            if (!moneyCollection || moneyCollection == 0) return;
            return new Money(moneyCollection).priceWithCurrency();
        },

        conversionFormat(percent) {
            return parseFloat(percent) + '%';
        },
        localSorting(ctx) {},
    },
    watch: {
        isbusy() {
            this.$emit('setIsLoading', this.isbusy);
        },
    },
};
</script>

<style lang="scss" scoped>
.bageInfo {
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    display: inline-flex !important;
}
.badge {
    padding-top: 4px;
    padding-bottom: 4px;
}
.world,
.other {
    white-space: nowrap;
}
.empty {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;

    .title {
        font-size: 32px;
    }
    .description {
        font-size: 16px;
    }
}
</style>
