<template lang="pug">
	.whiteCard.foreground-color.mb-4
		.d-flex.align-items-center.mb-2
			span.b3.text-grey.mr-10 {{ $t('project.projectedMRR') }}
			a(href="https://docs.paywall.pw/rus/obshee/analitika/mrr" target="_blank")
				question(color="grey")
		.mrrList(v-if="stats")
			template(v-for='(i,index) in stats.mrr')
				.divider(v-if="index")
				.income__item( :key='i.currency' v-html='incomeFormat(i)' :class="{'none-line': stats.mrr.length == 1}")
</template>
<script>
import { mapGetters } from 'vuex';
import question from '@/components/Icons/question.vue';
import { Money } from '@/models/money';

export default {
    components: {
        question,
    },
    computed: {
        ...mapGetters({
            stats: 'merchantPartner/statistics',
        }),
    },
    methods: {
        incomeFormat(i) {
            if (!i) return;
            return new Money(i).priceWithCurrency();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.mrrList {
    display: flex;
    gap: 50px;
    margin-top: 15px;
    .divider {
        background: var(--border-item-color);
        height: auto;
        width: 1px;
        display: block;
    }
    .income__item {
        color: var(--main-text-color);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.16;
    }
    @include respond-below(sm) {
        flex-direction: column;
        gap: 13px;
        .divider {
            width: 28px;
            height: 1px;
        }
    }
}
</style>
