<template lang="pug">
	.chart
		.topLine(@click="dataPointIndexCustom=null" ref="topLine" :class="{vertical:labelVertical}")
			.item(v-for="(item,index) in series" :class="{hide:!options.colors[index]}")
				.pin(:style="{background:options.colors[index]}")
				.title(v-html="item.name")
				.count(v-html="topLabel(item)")
		.chart-block
			apexchart(:type="type"  :options="options" :series="series" :key="key")
</template>
<script>
import { localePicker, PickerPeriods } from '@/constants/config';

import { Money } from '@/models/money';

const CHART_PERIODS = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
};
import filters from '@/filters';
import moment from 'moment-timezone';
import { DEVICE } from '@/utils/device';
import _ from 'lodash';

export default {
    props: ['height', 'type', 'data', 'labelVertical', 'value'],
    mounted() {
        this.vibrate();
        this.checkVertical();
        this.calculateTickAmount();
        window.addEventListener(
            'resize',
            event => {
                this.checkVertical();
                this.calculateTickAmount();
                this.calcKey();
            },
            true
        );
    },
    data() {
        return {
            dataPointIndexCustom: null,
            key: null,
            calculatedTickAmount: null,
        };
    },
    computed: {
        globalLabels() {
            let dates = this.getChartDates(this.periodFormatted, this.data[0].data);

            return dates.map(itm => {
                if (this.periodFormatted === CHART_PERIODS.WEEKLY) {
                    return {
                        m: itm,
                        l: itm.startOf('isoWeek').format('D.MM') + ' - ' + itm.endOf('isoWeek').format('D.MM'),
                    };
                }
                return { m: itm, l: itm.format('D.MM') };
            });
        },
        periodFormatted() {
            return this.data?.[0]?.period ?? CHART_PERIODS.WEEKLY;
        },
        options() {
            const period = this.periodFormatted;
            let dates = this.getChartDates(period, this.data[0].data);

            let yaxis = this.data.map((itm, index) => {
                const max = this.findMax(itm.data);
                return {
                    opposite: !!index,
                    tickAmount: 2,
                    min: 0,
                    seriesName: itm.title,

                    labels: {
                        minWidth: 32,
                        align: !index ? 'left' : 'right',
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#808080' : '#8292A1',
                        },
                        formatter: val => {
                            try {
                                return new Intl.NumberFormat('en-UA', {
                                    notation: 'compact',
                                    maximumFractionDigits: 0,
                                }).format(val);
                            } catch (e) {
                                console.log(e);
                            }
                            return val.toFixed();
                        },
                    },
                    crosshairs: {
                        show: true,
                        position: 'back',
                        stroke: {
                            color: this.isDarkColor ? '#444' : '#b6b6b6',
                            width: 1,
                            dashArray: 0,
                        },
                    },
                };
            });
            if (yaxis.length < 2) {
                yaxis = [..._.cloneDeep(yaxis), ..._.cloneDeep(yaxis)];
                yaxis[1].opposite = !yaxis[1].opposite;
                yaxis[1].labels.align = 'right';
            }

            return {
                stroke: {
                    curve: 'smooth',
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                grid: {
                    borderColor: this.isDarkColor ? '#444' : '#E4E7EA',
                    padding: {
                        right: this.isMobile ? 5 : 0,
                    },
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                    active: {
                        allowMultipleDataPointsSelection: true,
                        filter: {
                            type: 'none',
                            value: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Golos, Nunito, sans-serif',
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        let title = `<div class="apexcharts-tooltip-title">${this.formatter(dataPointIndex + 1, {
                            w,
                        })}</div>`;

                        this.dataPointIndexCustom = dataPointIndex;

                        let items = '';
                        const ttItems = Array.from(w.globals.tooltip.ttItems);

                        ttItems.forEach((ttItem, index) => {
                            if (index < this.data.length) {
                                let html = ttItem.outerHTML;

                                const valueOld = ttItem.getElementsByClassName('apexcharts-tooltip-text-y-value')[0]
                                    .innerHTML;

                                let newVal = series[index][dataPointIndex];
                                newVal = newVal.toFixed();

                                if (valueOld && newVal && valueOld !== newVal) {
                                    html = html.replace(valueOld, newVal);
                                }

                                items = items + html;
                            }
                        });

                        const customTooltipContent = document.createElement('div');
                        customTooltipContent.innerHTML = title + items;

                        return customTooltipContent.outerHTML;
                    },
                },
                //   labels: this.globalLabels,
                labels: this.getChartLabels(period, dates),

                xaxis: {
                    type: 'category',
                    tickPlacement: 'on',
                    labels: {
                        rotate: 0,
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#fff' : '#082846',
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        color: this.isDarkColor ? '#CBD2D8' : '#858B8F',
                    },
                    tickAmount: this.calculatedTickAmount,
                    tooltip: {
                        enabled: this.isMobile,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                        },
                        formatter: this.formatter,
                    },
                    crosshairs: {
                        show: true,
                        width: 2,
                        position: 'front',
                        opacity: 0.5,
                        stroke: {
                            color: this.isDarkColor ? '#808080' : '#8292A1',
                            width: 0,
                            dashArray: 0,
                        },
                    },
                },
                chart: {
                    offsetX: 5,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    locales: _.map(localePicker, l => {
                        return {
                            name: l.id,
                            options: {
                                months: l.monthNamesFull,
                                shortMonths: l.monthNames,
                                days: l.daysOfWeek,
                                shortDays: l.daysOfWeek,
                            },
                        };
                    }),
                    events: {
                        markerClick: (event, chartContext, config) => {
                            this.setTooltipMetricsValue(config);
                        },
                        mouseMove: (event, chartContext, config) => {
                            this.setTooltipMetricsValue(config);
                        },
                    },
                    defaultLocale: this.isLocaleRu ? 'ru' : 'en',
                },

                yaxis: yaxis,
                colors: this.data.map(itm => itm.color),
                fill: {
                    colors: this.data.map(itm => itm.color),
                },
            };
        },
        series() {
            let itms = this.data.map((itm, index) => {
                let values = Object.values(itm.data);

                values = values.map(itm => {
                    if (this.isMoney(itm)) {
                        return itm.amount / 100;
                    }
                    return itm;
                });

                return {
                    name: itm.title,
                    data: values,
                };
            });
            if (itms.length < 2) {
                itms = [..._.cloneDeep(itms), ..._.cloneDeep(itms)];
            }
            return itms;
        },
    },
    methods: {
        checkVertical() {
            const block = this.$refs.topLine;
            if (!block?.childNodes) return;
            let sumWidth = 0;
            for (const item of block.childNodes) {
                sumWidth += item.offsetWidth;
            }
            this.$emit('input', block.offsetWidth - 10 < sumWidth);
        },
        isMoney(item) {
            return typeof item === 'object';
        },
        calculateTickAmount() {
            if (this.periodFormatted == CHART_PERIODS.WEEKLY) {
                this.calculatedTickAmount = 2;
                return;
            }

            if (this.isMobile) {
                this.calculatedTickAmount = this.globalLabels.length % 2 == 0 ? 2 : 3;
            } else {
                if (window.innerWidth < 1100) {
                    this.calculatedTickAmount = 3;
                    return;
                }
                this.calculatedTickAmount = this.globalLabels.length % 2 == 0 ? 4 : 5;
            }
        },
        topLabel(item) {
            let num = _.sum(item.data);
            if (this.dataPointIndexCustom !== null && this.isMobile) {
                num = item.data[this.dataPointIndexCustom];
            }
            const rawData = this.data.find(itm => itm.title === item.name);
            const keys = _.keys(rawData.data);
            const val = rawData.data[keys[0]];
            if (this.isMoney(val)) {
                const money = new Money();
                money.currency = val.currency;
                money.amount = num;
                return money.priceWithCurrencyHTML();
            }
            return num.toLocaleString();
        },
        getDate(d, v) {
            let period = this.periodFormatted;
            let yw = d.split('-');

            if (period == PickerPeriods.WEEKLY) return moment(yw[0]).isoWeek(Number(yw[1])).startOf('week');

            if (period == PickerPeriods.MONTHLY) return moment(yw[0]).month(Number(yw[1]) - 1);

            return moment(d);
        },
        findMax(items) {
            let max;
            if (items) {
                max = _.max(
                    _.values(items).map(itm => {
                        if (this.isMoney(itm)) {
                            return itm.amount / 100;
                        }
                        return itm;
                    })
                );
            } else {
                max = _.max(_.map(this.series, d => _.max(_.values(d.data))));
            }
            return !max ? 10 : Math.ceil(max * 1.05);
        },
        vibrate() {
            let apexchartsBarArea = document.querySelectorAll('.apexcharts-bar-area');
            apexchartsBarArea.forEach(el => {
                el.addEventListener('click', () => {
                    window.navigator.vibrate(100);
                });
            });
        },
        setTooltipMetricsValue(config = null) {
            let data = this.graphSourcesData();

            this.tooltipMetricsValues = [];

            _.each(this.series, s => {
                this.tooltipMetricsValues.push({
                    name: s.name,
                    viewsCount: config == null ? _.sum(_.values(data[s.techName].data)) : s.data[config.dataPointIndex],
                    //    color: data[s.techName].color
                });
            });

            this.tooltipMetricsValues.sort((a, b) => {
                return b.viewsCount - a.viewsCount;
            });
        },
        graphSourcesData() {
            let o = {};
            let out = {};
            if (!this.metrics || !this.metrics.items) return {};

            _.each(this.metrics.graph, (a, ind) => {
                o[ind] = {};
                let data = {};
                let item = this.findSource(ind);

                _.each(a, (d, indD) => {
                    data[indD] = d;
                });

                o[ind].data = data;

                if (item) {
                    o[ind].color = item.color;
                    o[ind].label = item.getTitle(this.$t('project.analytics.sourcesLegend'));
                }
            });

            this.setTotal(o);

            _.each(this.metrics.items, (a, ind) => {
                if (a.checked) {
                    if (a.categoryType && o[a.categoryType]) {
                        out[a.categoryType] = o[a.categoryType];
                    } else out[a.id] = o[a.id];
                } else {
                    _.each(a.subCategory, s => {
                        if (s.checked) {
                            if (s.categoryType && o[s.categoryType]) {
                                out[s.categoryType] = o[s.categoryType];
                            } else out[s.id] = o[s.id];
                        }
                    });
                }
            });

            delete out.null;
            return out;
        },

        getChartDates(period = CHART_PERIODS.WEEKLY, data = {}) {
            return _.map(_.keys(data), d => {
                let yw = d.split('-');
                if (period == CHART_PERIODS.DAILY) return moment(d);
                if (period == CHART_PERIODS.WEEKLY) return moment(yw[0]).isoWeek(Number(yw[1])).startOf('week');
                if (period == CHART_PERIODS.MONTHLY) return moment(yw[0]).month(Number(yw[1]) - 1);

                return moment();
            });
        },
        getChartLabels(period = CHART_PERIODS.WEEKLY, dates = []) {
            let activity = dates;
            let names = _.keys(activity);

            if (CHART_PERIODS.DAILY == period) return _.map(activity, (v, i) => v.format('D.MM'));

            if (CHART_PERIODS.WEEKLY == period) {
                return _.map(activity, (v, i) => {
                    return v.format('D.MM') + ' - ' + v.add(6, 'days').format('D.MM');
                });
            }

            if (CHART_PERIODS.MONTHLY == period) return _.map(activity, (v, i) => v.format('MMM').slice(0, 3));

            return _.map(activity, (v, i) => v.format('D.MM'));
        },
        formatter(value, { w }) {
            if (this.globalLabels && this.globalLabels[value - 1] && this.globalLabels[value - 1].m) {
                if (_.isString(value) || isNaN(value)) value = _.findIndex(this.globalLabels, g => g.l == value) + 1;

                let v = this.globalLabels[value - 1].l;

                let period = this.periodFormatted;

                if (PickerPeriods.DAILY == period) {
                    if (DEVICE.isMobile())
                        return (
                            `${filters.capitalize(
                                this.globalLabels[value - 1].m
                                    .format('dddd')
                                    .slice(0, 3)
                                    .replace(/[оеяу]/gi, '')
                            )} ${v}.` + this.globalLabels[value - 1].m.format('YY')
                        );

                    return (
                        `<span class='apexcharts-tooltip-pre-title'">${filters.capitalize(
                            this.globalLabels[value - 1].m.format('dddd')
                        )}</span> ${v}.` + this.globalLabels[value - 1].m.format('YY')
                    );
                }

                if (CHART_PERIODS.WEEKLY == period) {
                    if (DEVICE.isMobile())
                        return (
                            `${filters.capitalize(
                                this.globalLabels[value - 1].m
                                    .format('dddd')
                                    .slice(0, 3)
                                    .replace(/[оеяу]/gi, '')
                            )} ${v}.` + this.globalLabels[value - 1].m.format('YYYY')
                        );

                    return `${v}.` + this.globalLabels[value - 1].m.format('YYYY');
                }
                if (PickerPeriods.MONTHLY == period) {
                    return (
                        this.globalLabels[value - 1].m.format('MMM').slice(0, 3) +
                        ' ' +
                        this.globalLabels[value - 1].m.format('YYYY')
                    );
                }

                if (DEVICE.isMobile())
                    return `<span class="text-nowrap">${this.globalLabels[value - 1].l}<br>${
                        this.globalLabels[value - 1].m._i
                    }</span>`;

                return `<span class="text-nowrap">${this.globalLabels[value - 1].l} ${
                    this.globalLabels[value - 1].m._i
                }</span>`;
            }
        },
        calcKey() {
            const string = JSON.stringify(this.options);
            let hash = 0;
            for (let i = 0; i < string.length; i++) {
                let code = string.charCodeAt(i);
                hash = (hash << 5) - hash + code;
                hash = hash & hash; // Convert to 32bit integer
            }
            this.hash = hash;
        },
    },
    watch: {
        data() {
            this.checkVertical();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.chart {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--border-dropdown-color);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    @include respond-below(sm) {
        border-bottom: 1px solid var(--border-dropdown-color) !important;
        padding: 0 0 10px !important;
        border-radius: 0;
        box-shadow: none;
    }

    .topLine {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        gap: 10px;

        &.vertical {
            flex-direction: column;
            align-items: flex-start;
        }
        .item {
            display: flex;
            gap: 10px;
            &.hide {
                opacity: 0;
            }
            .pin {
                width: 4px;
                border-radius: 20%;
            }
            .title {
                color: var(--grey-text-color);
            }
            .count {
                font-weight: 600;
                font-size: 18px;
            }
        }
        @include respond-below(sm) {
            flex-direction: column;
        }
    }
    @include respond-below(sm) {
        padding: 0;
        border: none;
    }
    .chart-block {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        &::v-deep {
            //.apexcharts-yaxis-label
            text-align: left;
            @include respond-below(sm) {
                &::v-deep {
                    .apexcharts-tooltip {
                        display: none;
                    }
                }
            }
        }
    }
    &::v-deep {
        .apexcharts-xaxis-texts-g {
            transform: translate(15px, 10px) scale(0.96);
        }
        @include respond-below(sm) {
            transform: none;
            .apexcharts-tooltip {
                opacity: 0;
            }
        }
    }
}
</style>
