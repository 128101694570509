<template lang="pug">
	b-modal#modalmerchantpartnerhasnoprojects(ref='modal' size='md' hide-footer hide-header :centered='!isMobile' @hide="onHide")
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross
		.body
			.title(v-html="$t('merchantPartners.modals.hasNoProjects.title')")
			.descr(v-html="$t('merchantPartners.modals.hasNoProjects.descr')")
			.info(v-html="$t('merchantPartners.modals.hasNoProjects.info')")

		b-btn.w-100(variant='primary' @click='close') {{ $t('resources.btnFine') }}
</template>
<script>
export default {
    name: 'MerchantPartnerHasNoProjects',
    mounted() {
        this.$refs.modal.show();
    },
    methods: {
        close() {
            this.$refs.modal.hide();
            this.onHide();
        },
        onHide() {
            localStorage.hasNoProject = 1;
        },
    },
};
</script>
<style scoped lang="scss">
.body {
    font-size: 14px;
    line-height: 1.55;
    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .descr {
        color: var(--grey-text-color);
        margin-bottom: 10px;
    }
    .info {
        color: var(--primary-text-color);
        border-radius: 8px;
        background: var(--primary-light-color);
        padding: 20px;
        margin-bottom: 20px;
    }
}
</style>
